/* eslint-disable class-methods-use-this */
import { ILOidcLocation } from "@axa-fr/react-oidc";

export class OidcLocation implements ILOidcLocation {
  open(url: string) {
    window.location.replace(url);
  }

  reload() {
    window.location.reload();
  }

  getCurrentHref() {
    return window.location.href;
  }

  getPath() {
    const isValidPath = (path: string) => /^[a-zA-Z0-9/_-]*$/.test(path);
    const isValidQuery = (query: string) =>
      !query.includes("redirect") && query.length < 256;
    const { location } = window;
    const safePath = isValidPath(location.pathname) ? location.pathname : "/";
    const safeSearch = isValidQuery(location.search) ? location.search : "";
    const safeHash = location.hash.startsWith("#") ? location.hash : "";
    return safePath + safeSearch + safeHash;
  }

  getOrigin(): string {
    return window.origin;
  }
}
