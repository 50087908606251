import { useState } from "react";
import { Box, Typography } from "@mui/material";
import { sxStyles } from "./PaymentFooterLinks.styles";
import { PATHS } from "lib/routing";
import IMAGES from "lib/assets/images";
import AddOtherBillDialog from "../AddOtherBillDialog/AddOtherBillDialog";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import CompactCard from "lib/components/cards/CompactCard/CompactCard";
import toTitleCase from "lib/util/StringUtil/toTitleCase/toTitleCase";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "lib/redux/hooks";
import { selectUser } from "lib/redux/user/selectors";

const OTHER_PAYMENT_SERVICES = "Other Payment Services";

const CARDS = {
  addOtherBill: {
    title: "Add Other Bill",
    body: "Pay someone else's bill",
  },
  paidBills: {
    title: "Fully-Paid Bills",
    body: "Download for claims purposes or personal reference",
  },
  transactionHistory: {
    title: "App Payment Status",
    body: "Check status of payments made via the app",
  },
  otherEnquiries: {
    title: "Other Enquiries",
    body: "Get help on your payment and billing matters",
  },
};

const PaymentFooterLinks = () => {
  const navigate = useNavigate();
  const classes = sxStyles();
  const isPatient = useAppSelector(selectUser).isPatient;

  const [openAddBillDialog, setOpenAddBillDialog] = useState(false);

  const handleAddOtherBillsOnClick = () => {
    logEventToGoogleAnalytics(EVENTS.SELECT_ADD_OTHER_BILL_LANDING);
    setOpenAddBillDialog(true);
  };

  const handlePaidBillsOnClick = () => {
    logEventToGoogleAnalytics(EVENTS.SELECT_PAID_BILLS_LANDING);
    navigate(PATHS.PAYMENT_PAID_BILLS.path);
  };

  const handleTransactionOnClick = () => {
    logEventToGoogleAnalytics(EVENTS.SELECT_TRANSACTION_HISTORY);
    navigate(PATHS.PAYMENT_TRANSACTION_HISTORY.path);
  };

  const handleOtherEnquiriesOnClick = () => {
    logEventToGoogleAnalytics(EVENTS.CLICK_PAYMENT_OTHER_ENQUIRIES);
    navigate(PATHS.PAYMENT_OTHER_ENQUIRIES.path);
  };

  return (
    <Box sx={classes.footerSectionContainer}>
      <Box sx={classes.titleContainer}>
        <Typography sx={classes.pageTitle}>
          {toTitleCase(OTHER_PAYMENT_SERVICES)}
        </Typography>
      </Box>

      <CompactCard
        title={CARDS.paidBills.title}
        body={CARDS.paidBills.body}
        titleIcon={IMAGES.payment.PaidBills}
        onClickCard={handlePaidBillsOnClick}
      />

      {isPatient && (
        <CompactCard
          title={CARDS.transactionHistory.title}
          body={CARDS.transactionHistory.body}
          titleIcon={IMAGES.payment.TransactionHistory}
          onClickCard={handleTransactionOnClick}
        />
      )}

      <CompactCard
        title={CARDS.addOtherBill.title}
        body={CARDS.addOtherBill.body}
        titleIcon={IMAGES.payment.AddOtherBill}
        onClickCard={handleAddOtherBillsOnClick}
      />

      <CompactCard
        title={CARDS.otherEnquiries.title}
        body={CARDS.otherEnquiries.body}
        titleIcon={IMAGES.payment.OtherEnquiries}
        onClickCard={handleOtherEnquiriesOnClick}
      />

      <AddOtherBillDialog
        open={openAddBillDialog}
        hostedOnBillSummaryPage={false}
        handleClose={() => {
          setOpenAddBillDialog(false);
        }}
      />
    </Box>
  );
};

export default PaymentFooterLinks;
