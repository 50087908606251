import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PATHS } from "lib/routing";
import { Box, CircularProgress } from "@mui/material";
import { ConfirmationPageProps } from "./ConfirmationPage.types";
import Page from "lib/components/layout/Page/Page";
import VerticalSpreadLayout from "lib/components/layout/VerticalSpreadLayout/VerticalSpreadLayout";
import UserProfileContainer from "ui/appointment/components/common/user/UserProfile/UserProfileContainer";
import ButtonsFooter from "lib/components/buttons/ButtonsFooter/ButtonsFooter";
import ConfirmationModal from "lib/components/modals/ConfirmationModal/ConfirmationModal";
import { mobileNavigate } from "lib/routing/navigate/navigate";
import { formatAddToCalendarURI } from "lib/mobileIntegration/addToCalendar/formatUrl";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import ConfirmationDetails from "../ConfirmationDetails/ConfirmationDetails";
import { CONNECT_TO_LIVE_AGENT_BUTTON_TEXT } from "lib/components/navigation/AppRouteRenderer/ErrorModal/ErrorModal";
import MapRawStringToReactElement from "lib/util/ReactComponentUtil/mapRawStringToReactElement/MapRawStringtoReactElement";
import { AxiosError } from "axios";
import { useAppSelector } from "lib/redux/hooks";
import { selectCreateAppointment } from "ui/appointment/ducks/selectors";

const ConfirmationPage = ({
  institution,
  service,
  slotDateTime,
  slotId,
  memberIdentifier,
  visitTypeId,
  institutionCode,
  appointmentId,
  location,
  zoneInfo,
  isHsgAppt,
  calendarLocation,
  calendarTitle,
  appointmentType,
  onBook,
  handleErrorModal,
}: ConfirmationPageProps) => {
  const navigate = useNavigate();

  // Redux States
  const { selectedSpecialty } = useAppSelector(selectCreateAppointment);

  // Local states
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Dialog open & close handlers
  const [afterCareMessage, setAfterCareMessage] = useState<string | null>(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Request Handler
  const sendCreateAppointmentRequest = useCallback(async () => {
    try {
      // make the booking
      logEventToGoogleAnalytics(EVENTS.VIEW_APPOINTMENT_CONFIRMATION);
      setIsLoading(true);
      const response = await onBook(
        {
          visitTypeId,
          memberIdentifier,
          slotDateTime,
          slotId,
          institutionCode,
          isHsgAppt,
        },
        selectedSpecialty ? selectedSpecialty.isFirstVisit : false,
      );
      setAfterCareMessage(response.PatientInstruction);
      handleOpen();
    } catch (error) {
      if (error instanceof AxiosError) {
        handleErrorModal(
          true,
          error.response?.data.Message,
          error.response?.data.IsLiveChatEnabled
            ? CONNECT_TO_LIVE_AGENT_BUTTON_TEXT
            : undefined,
        );
      } else {
        handleErrorModal(true, null);
      }
    } finally {
      setIsLoading(false);
    }
  }, [
    onBook,
    visitTypeId,
    memberIdentifier,
    slotDateTime,
    slotId,
    institutionCode,
    isHsgAppt,
    handleErrorModal,
    selectedSpecialty,
  ]);

  return (
    <Page>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: 4,
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {/* Confirmation Page & Details */}
          <VerticalSpreadLayout>
            <Box>
              <UserProfileContainer showMakeAppointmentButton={false} />
              <ConfirmationDetails
                institution={institution}
                location={location}
                zoneInfo={zoneInfo}
                service={
                  service
                    ? service
                    : selectedSpecialty
                      ? `Private ${selectedSpecialty?.description} - First Visit`
                      : ""
                }
                slotDateTime={slotDateTime}
                appointmentType={appointmentType}
              />
            </Box>
            <Box sx={{ m: 2 }}>
              <ButtonsFooter
                nextButtonText="Book this appointment"
                cancelButtonText="Back"
                onClickNext={sendCreateAppointmentRequest}
                onClickCancel={() => navigate(-1)}
              />
            </Box>
          </VerticalSpreadLayout>

          {/* Save to Calendar Modal */}
          <ConfirmationModal
            open={open}
            title="Appointment Booked"
            body={
              afterCareMessage !== null
                ? MapRawStringToReactElement(afterCareMessage)
                : null
            }
            hideNextButton={!calendarLocation && !location}
            nextButtonText="Add to calendar"
            cancelButtonText="Go to Appointment"
            onClickNext={() => {
              mobileNavigate(
                formatAddToCalendarURI(
                  "0",
                  slotDateTime,
                  calendarLocation ? calendarLocation : location,
                  institution || "",
                  calendarTitle,
                ),
              );
              navigate(PATHS.APPOINTMENT_MOBILE.path, { replace: true });
            }}
            onClickCancel={() => {
              navigate(PATHS.APPOINTMENT_MOBILE.path, { replace: true });
            }}
            onClose={handleClose}
          />
        </>
      )}
    </Page>
  );
};

export default ConfirmationPage;
