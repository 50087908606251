import MakeNewAppointment from "./appointment/MakeNewAppointment.svg";
import NewAppointment from "./appointment/NewAppointment.svg";
import DatePickerIcon from "./appointment/DatePickerIcon.svg";
import Camera from "./appointment/Camera.png";
import NoUpcomingAppointment from "./appointment/NoUpcomingAppointment.svg";
import NoMissedAppointment from "./appointment/NoMissedAppointment.svg";
import NoOpenAppointment from "./appointment/NoOpenAppointment.svg";
import Refill from "./medrefill/Refill.svg";
import Topup from "./medrefill/Topup.svg";
import PrescribedMedication from "./medrefill/PrescribedMedication.svg";
import PageError from "./appointment/error.svg";
import PullToRefreshBounching from "./appointment/PullToRefreshBounching.gif";
import TickAnimation from "./appointment/TickAnimation.gif";
import NoImageSquare from "./appointment/NoImageSquare.svg";
import NoImageCircle from "./appointment/NoImageCircle.svg";
import TapToSetNewDate from "./appointment/TabToSetNewDate.svg";
import CalendarEdit from "./appointment/CalendarEdit.svg";
import DottedLoading from "./appointment/DottedLoading.gif";
import Share from "./appointment/share.svg";
import AddOtherBill from "./payment/AddOtherBill.svg";
import PaidBills from "./payment/PaidBills.svg";
import TransactionHistory from "./payment/TransactionHistory.svg";
import AddOtherBillImage from "./payment/AddOtherBillImage.svg";
import NoOutstandingBills from "./payment/NoOutstandingBills.svg";
import NoPaidBills from "./payment/NoPaidBills.svg";
import NoTransactionHistory from "./payment/NoTransactionHistory.svg";
import VisaMaster from "./payment/VisaMaster.svg";
import VisaMasterAm from "./payment/VisaMasterAM.svg";
import Nets from "./payment/Nets.svg";
import VisaMasterEnabled from "./payment/VisaMasterEnabled.svg";
import VisaMasterAMEnabled from "./payment/VisaMasterAMEnabled.svg";
import VisaMasterAMDisabled from "./payment/VisaMasterAMDisabled.svg";
import eNetsEnabled from "./payment/eNetsEnabled.svg";
import VisaMasterDisabled from "./payment/VisaMasterDisabled.svg";
import eNetsDisabled from "./payment/eNetsDisabled.svg";
import TransitionToGateway from "./payment/TransitionToGateway.svg";
import Pending from "./payment/Pending.svg";
import Unsuccessful from "./payment/Unsuccessful.svg";
import Vaccination from "./mhm/Vaccination.svg";
import ChronicDiseaseScreening from "./mhm/ChronicDiseaseScreening.svg";
import CancerScreening from "./mhm/CancerScreening.svg";
import ChronicDiseaseManagement from "./mhm/ChronicDiseaseManagement.svg";
import CategoryDefaultImage from "./mhm/CategoryDefaultImage.svg";
import ExternalLinkIcon from "../icons/general/ExternalLinkIcon.svg";
import SFL_Logo from "./mhm/SFL_Logo.png";
import NoRequestedReport from "./mrrp/NoRequestedReport.svg";
import Successful from "./payment/PaymentSuccessful.svg";
import ProfileCamera from "./myProfile/ProfileCamera.svg";
import MyInfo from "./myProfile/MyInfo.svg";
import RightArrow from "./myProfile/RightArrow.png";
import TickIcon from "./mhm/TickIcon.svg";
import SingHealth from "./appointment/SingHealth.svg";
import CloseIcon from "./mrrp/Close_Icon.svg";
import MedRefillIcon from "./medrefill/MedRefillIcon.svg";
import NoRecords from "./medrefill/NoRecords.svg";
import QuestionMark from "./medrefill/questionMark.svg";
import ReorderMedicationIcon from "./medrefill/reorderMedicationIcon.svg";
import HTLandingBannerImage from "./healthTogether/HTLandingNew.png";
import HDO_Logo from "./mhm/HDO_Logo.png";
import PMSyncParticulars from "./patientMaster/PMSyncParticulars.svg";
import CommunityCareIcon from "./healthTogether/exploreHealthTogetherSection/CommunityCare.svg";
import EventsIcon from "./healthTogether/exploreHealthTogetherSection/Events.svg";
import HealthLibraryIcon from "./healthTogether/exploreHealthTogetherSection/HealthLibrary.svg";
import SelfAssessmentIcon from "./healthTogether/exploreHealthTogetherSection/SelfAssessment.svg";
import SelfServeIcon from "./healthTogether/exploreHealthTogetherSection/SelfServe.svg";
import HealthLibraryBannerImage from "./healthTogether/healthLibraryPage/healthLibraryBanner.png";
import aZIcon from "./healthTogether/healthLibraryPage/aZicon.svg";
import OtherToolsImage from "./healthTogether/selfHelpToolsPage/OtherToolsImage.png";
import SelfServeBannerImage from "./healthTogether/selfServeResourcesPage/SelfServeBannerImage.png";
import CommunityCareBannerImage from "./healthTogether/communityCarePage/CommunityCareBannerImage.png";
import FindADoctorIcon from "./healthTogether/selfServeResourcesPage/FindADoctor.svg";
import MHMIcon from "./healthTogether/selfHelpToolsPage/MHMIcon.svg";
import OtherEnquiries from "./payment/OtherEnquiries.svg";
import PrescriptionListIcon from "./medrefill/PrescriptionListIcon.svg";
import RepeatPrescriptionIcon from "./medrefill/RepeatPrescriptionIcon.svg";
import ArrowRightIcon from "../icons/general/ArrowRightIcon.svg";
import ArrowRightIconDisabled from "../icons/general/ArrowRightIconDisabled.svg";
import AddIcon from "../icons/general/AddIcon.svg";
import MinusIcon from "../icons/general/MinusIcon.svg";
import AddIconDisabled from "../icons/general/AddIconDisabled.svg";
import DownloadIcon from "../icons/general/DownloadIcon.svg";
import DownloadBlueIcon from "../icons/general/DownloadBlueIcon.svg";
import DeleteIcon from "../icons/general/DeleteIcon.svg";
import FilterIcon from "../icons/general/FilterIcon.svg";
import EditIcon from "../icons/general/EditIcon.svg";
import NewRequestIcon from "../images/mrrp/NewRequestIcon.svg";
import AddToCalendarIcon from "../images/appointment/AddToCalendarIcon.svg";
import OrderMedicationIcon from "../images/medrefill/OrderMedicationIcon.svg";
import ErrorIcon from "../icons/general/Error.svg";
import CheckIcon from "../icons/general/CheckIcon.svg";
import ArrowForward from "../icons/general/ArrowForward.svg";
import InformationIcon from "../icons/general/InformationIcon.svg";
import InformationAlertIcon from "../icons/general/InformationAlertIcon.svg";
import InformationErrorIcon from "../icons/general/InformationErrorIcon.svg";
import WarningIcon from "../icons/general/WarningIcon.svg";
import CancelIcon from "../icons/general/CancelIcon.svg";
import GeneralCloseIcon from "../icons/general/CloseIcon.svg";
import HelpOutline from "../icons/general/HelpOutline.svg";
import RefreshIcon from "../icons/general/RefreshIcon.svg";
import ForwardIcon from "../icons/general/ForwardIcon.svg";
import KeyboardArrowLeft from "../icons/general/KeyboardArrowLeft.svg";
import ExpandIcon from "../icons/general/ExpandIcon.svg";
import CheckBoxIcon from "../icons/general/CheckBoxIcon.svg";
import CheckBoxIconDisabled from "../icons/general/CheckBoxIconDisabled.svg";
import CheckBoxOutlineBlankIcon from "../icons/general/CheckBoxOutlineBlankIcon.svg";
import CheckBoxOutlineBlankIconDisabled from "../icons/general/CheckBoxOutlineBlankIconDisabled.svg";
import RadioButtonCheckedIcon from "../icons/general/RadioButtonCheckedIcon.svg";
import RadioButtonUncheckedIcon from "../icons/general/RadioButtonUncheckedIcon.svg";
import RadioButtonIconDisabled from "../icons/general/RadioButtonIconDisabled.svg";
import FullRadioButtonCheckedIcon from "../icons/general/FullRadioButtonChecked.svg";
import FullRadioButtonUncheckedIcon from "../icons/general/FullRadioButtonUnchecked.svg";
import FullRadioButtonIconDisabled from "../icons/general/FullRadioButtonDisabled.svg";
import SearchIcon from "../icons/general/SearchIcon.svg";
import AmexEnabled from "../images/payment/AmexEnabled.svg";
import AmexDisabled from "../images/payment/AmexDisabled.svg";
import BarcodeIcon from "../images/appointment/BarcodeIcon.svg";
import IHaveArrivedTutorial from "../images/appointment/IHaveArrivedTutorial.svg";
import AccordionIcon from "../icons/general/AccordionIcon.svg";
import EditBlueIcon from "../icons/general/EditBlueIcon.svg";
import LoadingIcon from "../icons/general/Spinner.gif";
import DeleteBlueIcon from "../icons/general/DeleteBlueIcon.svg";
import DeleteGreyIcon from "../icons/general/DeleteGreyIcon.svg";
import CHAMPIcon from "../images/healthTogether/selfHelpToolsPage/CHAMPIcon.svg";
import NoImageUploaded from "../images/medrefill/NMAPlaceholder.png";

/**
 * Object that contains all images stored within react.
 * It should be categorized to allow for quicker access and maintenance of  icons and images.
 * Icons should be named with suffix "Icon" to prevent confusion with React components. e.g. "Link" should not be used as an icon name as it is also the name of React component in react-router
 */
const IMAGES = {
  general: {
    LoadingIcon,
    ArrowRightIcon,
    ArrowRightIconDisabled,
    ExternalLinkIcon,
    AddIcon,
    AddIconDisabled,
    MinusIcon,
    Share,
    TickIcon,
    QuestionMark,
    CloseIcon,
    Successful,
    Pending,
    Unsuccessful,
    DownloadIcon,
    DownloadBlueIcon,
    DeleteIcon,
    DeleteBlueIcon,
    DeleteGreyIcon,
    FilterIcon,
    EditIcon,
    EditBlueIcon,
    DatePickerIcon,
    Camera,
    DottedLoading,
    PageError,
    PullToRefreshBounching,
    TickAnimation,
    NoImageSquare,
    NoImageCircle,
    ErrorIcon,
    CheckIcon,
    ArrowForward,
    InformationIcon,
    InformationAlertIcon,
    InformationErrorIcon,
    WarningIcon,
    CancelIcon,
    GeneralCloseIcon,
    HelpOutline,
    RefreshIcon,
    ForwardIcon,
    KeyboardArrowLeft,
    ExpandIcon,
    CheckBoxIcon,
    CheckBoxIconDisabled,
    CheckBoxOutlineBlankIcon,
    CheckBoxOutlineBlankIconDisabled,
    RadioButtonCheckedIcon,
    RadioButtonUncheckedIcon,
    RadioButtonIconDisabled,
    SearchIcon,
    FullRadioButtonCheckedIcon,
    FullRadioButtonUncheckedIcon,
    FullRadioButtonIconDisabled,
    AccordionIcon,
  },
  healthTogetherMHM: {
    HTLandingBannerImage,
    SelfServeBannerImage,
    CommunityCareBannerImage,
    OtherToolsImage,
    HealthLibraryBannerImage,
    FindADoctorIcon,
    MHMIcon,
    aZIcon,
    CommunityCareIcon,
    EventsIcon,
    HealthLibraryIcon,
    SelfAssessmentIcon,
    SelfServeIcon,
    HDO_Logo,
    SFL_Logo,
    CategoryDefaultImage,
    ChronicDiseaseManagement,
    Vaccination,
    ChronicDiseaseScreening,
    CancerScreening,
    CHAMPIcon,
  },
  medrefill: {
    RepeatPrescriptionIcon,
    PrescriptionListIcon,
    MedRefillIcon,
    OrderMedicationIcon,
    ReorderMedicationIcon,
    NoRecords,
    Refill,
    Topup,
    PrescribedMedication,
    NoImageUploaded,
  },
  appointment: {
    SingHealth,
    AddToCalendarIcon,
    MakeNewAppointment,
    NewAppointment,
    NoUpcomingAppointment,
    NoMissedAppointment,
    NoOpenAppointment,
    TapToSetNewDate,
    CalendarEdit,
    BarcodeIcon,
    IHaveArrivedTutorial,
  },
  pm: {
    PMSyncParticulars,
    RightArrow,
    MyInfo,
  },
  profile: {
    ProfileCamera,
  },
  mrrp: {
    NoRequestedReport,
    NewRequestIcon,
  },
  payment: {
    TransitionToGateway,
    AddOtherBill,
    PaidBills,
    TransactionHistory,
    OtherEnquiries,
    AddOtherBillImage,
    NoOutstandingBills,
    NoPaidBills,
    NoTransactionHistory,
    VisaMaster,
    VisaMasterAm,
    Nets,
    VisaMasterAMEnabled,
    VisaMasterAMDisabled,
    VisaMasterEnabled,
    VisaMasterDisabled,
    eNetsEnabled,
    eNetsDisabled,
    AmexEnabled,
    AmexDisabled,
  },
};

export default IMAGES;
