import { Dispatch, AnyAction } from "redux";
import { connect } from "react-redux";

import { formatDateToISO } from "lib/util/DateTimeUtil/formatDateToISO/formatDateToISO";
import { getDateFromISOString } from "lib/util/DateTimeUtil/getDateFromISOString/getDateFromISOString";
import { RootState } from "lib/redux/root/redux.types";
import { createAppointment } from "api/appointment/CreateAppointment/createAppointment";

import ConfirmationPage from "./ConfirmationPage";
import { handleErrorModal } from "lib/redux/notifications";
import { onBookReq } from "./ConfirmationPage.types";
import { createLinkedAppointment } from "api/appointment/CreateLinkedAppointment/createLinkedAppointment";

const selectSlotDateTime = (state: RootState): Date => {
  if (state.appointments.create.selectedAvailableSlotDateTime) {
    const slotDateTime = getDateFromISOString(
      state.appointments.create.selectedAvailableSlotDateTime,
    );

    if (slotDateTime) {
      return slotDateTime;
    }
  }

  return new Date();
};

const mapStateToProps = (state: RootState) => {
  return {
    memberIdentifier: state.user.memberIdentifier,
    institution: state.appointments.create?.selectedInstitution || "",
    service: state.appointments.create?.selectedService || "",
    slotDateTime: selectSlotDateTime(state),
    slotId: state.appointments.create?.selectedAvailableSlotId,
    institutionCode: state.appointments.appointmentMeta.institutionCode,
    appointmentId: state.appointments.appointmentMeta.appointmentId,
    location: state.appointments.appointmentMeta.location,
    visitTypeId: state.appointments.appointmentMeta.visitTypeId,
    appointmentType: state.appointments.appointmentMeta.appointmentType,
    calendarTitle: state.appointments.appointmentMeta.calendarTitle,
    calendarLocation: state.appointments.appointmentMeta.calendarLocation,
    zoneInfo: state.appointments.appointmentMeta.zoneInfo,
    isHsgAppt: state.appointments.appointmentMeta.isHsgAppt || false,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    onBook: async (
      {
        visitTypeId,
        memberIdentifier,
        slotDateTime,
        slotId,
        institutionCode,
        isHsgAppt,
      }: onBookReq,
      isFV: boolean,
    ) => {
      if (isFV) {
        return await createLinkedAppointment(
          memberIdentifier,
          institutionCode,
          [slotId],
        );
      } else {
        return await createAppointment({
          visitTypeId,
          memberIdentifier,
          start: formatDateToISO(slotDateTime),
          slotId,
          institutionCode,
          isHsgAppt,
        });
      }
    },
    handleErrorModal: (
      open: boolean,
      message: string | null,
      buttonText?: string | null,
    ) => {
      dispatch(handleErrorModal({ open, message, buttonText }));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationPage);
