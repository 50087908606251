import { decode } from "html-entities";
import parser from "html-react-parser";

/**
 * This will convert string containing any html into jsx
 * @param rawString String that contains some html
 * @returns jsx
 */
const MapRawStringToReactElement = (rawString: string) => {
  // !exp decodes any named and numerical character references to html
  // !exp eg "&#36" decodes to "$"
  rawString = decode(String(rawString));
  return <>{parser(rawString)}</>;
};

export default MapRawStringToReactElement;
