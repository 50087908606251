import { color } from "lib/theme/color";
import { size } from "lib/theme/size";
import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    card: {
      boxShadow: "0px 0px 6px " + color.RGBA_52,
      backgroundColor: theme.palette.common.white,
      display: "flex",
      marginTop: theme.spacing(1.5),
      borderRadius: 0,
      "& .MuiCardActions-root": {
        padding: theme.spacing(1, 0.75),
      },
      padding: theme.spacing(1, 0, 1, 0),
    },
    cardDetailWrapper: {
      width: "100%",
      padding: 0,
      "&:last-child": {
        paddingBottom: theme.spacing(1.5), // deactivate default padding
      },
    },
    cardDetailsWithoutDate: {
      padding: 0,
      minHeight: "100%",
      wordBreak: "break-word",
    },
    cardDetails: {
      borderLeft: `2px solid ${theme.palette.error.main}`,
      padding: theme.spacing(0, 0, 0, 1),
      minHeight: "100%",
      wordBreak: "break-word",
      flex: 1,
    },
    cardTitle: {
      color: theme.palette.text.primary,
      typography: theme.typography.body2_semibold,
      marginBottom: theme.spacing(1),
    },
    cardLabel: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_semibold,
      marginTop: theme.spacing(1),
    },
    cardContent: {
      color: theme.palette.grey[400],
      typography: theme.typography.body2_regular,
    },
    cardAmount: {
      color: theme.palette.warning.main,
      fontSize: size.TITLE_FONTSIZE,
      fontWeight: theme.typography.fontWeightMedium,
    },

    icon: {
      paddingLeft: theme.spacing(1),
    },

    cardHeader: {
      "&.MuiCardHeader-root": {
        padding: theme.spacing(0.5, 1, 0, 1),
        marginRight: theme.spacing(0),
        textAlign: "center",
        alignItems: "start",
        display: "block",
        "& .MuiCardHeader-action": {
          color: theme.palette.grey[400],
          typography: theme.typography.body2_regular,
          padding: theme.spacing(1, 0),
          marginTop: theme.spacing(-1),
          marginBottom: theme.spacing(-1),
          alignItems: "start",
          width: "100%",
        },
      },
      "& .MuiCardHeader-title": {
        color: theme.palette.grey[400],
        typography: theme.typography.body2_regular,
        lineHeight: "18px",
        textTransform: "uppercase",
      },
      "& .MuiCardHeader-subheader": {
        color: theme.palette.warning.main,
        fontSize: 30,
        fontWeight: theme.typography.fontWeightBold,
        lineHeight: 1,
        textTransform: "uppercase",
      },
    },
  } satisfies SxObjType;
};

export { sxStyles };
