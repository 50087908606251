import { useEffect } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
} from "@mui/material";
import { ConfirmationModalProps } from "./ConfirmationModal.types";
import { sxStyles } from "./ConfirmationModal.styles";
import IMAGES from "lib/assets/images";
import { IconButton } from "@mui/material";
import { MessageActions } from "lib/routing/messageChannel/messageActions";
import { useAppDispatch } from "lib/redux/hooks";
import { setMessageToSend } from "lib/redux/navigation/navigationSlice";
import { NON_LANDING_PAGE_PATHS_BACK_DISABLE } from "lib/routing/messageChannel/pathsToSendMessage";
import { useLocation } from "react-router-dom";
import { retrievePath } from "lib/routing";
import SimpleCheckbox from "lib/components/formInputs/Checkbox/SimpleCheckbox";
import MapRawStringToReactElement from "lib/util/ReactComponentUtil/mapRawStringToReactElement/MapRawStringtoReactElement";

/* A function that returns a Dialog component. Disables android physical back button when open and reenables back button on close. 
Dev: take note that if the page has disableBackButton, then you will need to pass in disableBackButton again when the modal is closed */
const ConfirmationModal = ({
  title,
  titleBold = true,
  isNextButtonDisabled = false,
  body,
  hideNextButton = false,
  nextButtonText,
  nextButtonLabel,
  hideCancelButton = false,
  cancelButtonText,
  cancelButtonLabel,
  hideAdditionalButton = true,
  additionalButtonText,
  additionalButtonLabel,
  open = false,
  enableBGBlur = false,
  position = "center",
  checkBoxText,
  isChecked = false,
  onClose,
  onClickNext,
  onClickCancel,
  onClickAdditional,
  onCheck,
  disableResetOnNext = false,
  showCloseButton = false,
}: ConfirmationModalProps) => {
  const classes = sxStyles({
    position,
    enableBGBlur,
    titleBold,
  });
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const path = retrievePath(pathname);

  const resetBackButton = () => {
    if (NON_LANDING_PAGE_PATHS_BACK_DISABLE.includes(path?.path ?? "")) {
    } else {
      dispatch(setMessageToSend(MessageActions.back("ENABLED")));
    }
  };

  useEffect(() => {
    if (open) {
      dispatch(setMessageToSend(MessageActions.back("DISABLED")));
    }
  }, [dispatch, open]);

  const handleClickNext = () => {
    if (!disableResetOnNext) {
      resetBackButton();
    }
    if (onClickNext) {
      onClickNext();
    }
  };

  const handleClickCancel = () => {
    resetBackButton();
    if (onClickCancel) {
      onClickCancel();
    }
  };

  const handleClickAdditional = () => {
    resetBackButton();
    if (onClickAdditional) {
      onClickAdditional();
    }
  };

  const handleClose = () => {
    resetBackButton();
    if (onClose) onClose();
  };

  const handleOnCheck = () => {
    if (onCheck) onCheck();
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="lg"
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          if (onClose) onClose();
        }
      }}
      sx={classes.dialogContainer}
    >
      <Box sx={classes.dialog}>
        {showCloseButton && (
          <IconButton
            aria-label="closeIcon"
            sx={classes.closeButton}
            onClick={handleClose}
          >
            <Box component={"img"} src={IMAGES.general.GeneralCloseIcon} />
          </IconButton>
        )}

        {title && (
          <DialogTitle sx={classes.dialogTitle}>
            <Typography sx={classes.dialogTitleContent}>{title}</Typography>
          </DialogTitle>
        )}

        <DialogContent sx={classes.dialogContent}>{body}</DialogContent>

        <Box sx={classes.contentContainer}>
          {/* Next */}
          {hideNextButton ? null : (
            <>
              <Button
                variant="contained"
                size="large"
                fullWidth
                onClick={isNextButtonDisabled ? undefined : handleClickNext}
                data-testid="nextButton"
              >
                {nextButtonText}
              </Button>

              {nextButtonLabel ? (
                <Typography sx={classes.buttonLabel}>
                  {nextButtonLabel}
                </Typography>
              ) : null}
            </>
          )}

          {/* Cancel */}
          {hideCancelButton ? null : (
            <>
              <Button
                variant="outlined"
                size="large"
                fullWidth={true}
                onClick={handleClickCancel}
                data-testid="cancelButton"
              >
                {cancelButtonText}
              </Button>

              {cancelButtonLabel ? (
                <Typography sx={classes.buttonLabel}>
                  {cancelButtonLabel}
                </Typography>
              ) : null}
            </>
          )}

          {/* Additional button */}
          {hideAdditionalButton ? null : (
            <>
              <Button
                variant="outlined"
                color="primary"
                fullWidth={true}
                onClick={handleClickAdditional}
                data-testid="additionalButton"
              >
                {additionalButtonText}
              </Button>

              {additionalButtonLabel ? (
                <Box sx={classes.additionalButtonLabel}>
                  {additionalButtonLabel}
                </Box>
              ) : null}
            </>
          )}

          {/* Checkbox */}
          {checkBoxText ? (
            <SimpleCheckbox
              checked={isChecked}
              value={""}
              label={MapRawStringToReactElement(checkBoxText ?? "")}
              handleChange={handleOnCheck}
            />
          ) : null}
        </Box>
      </Box>
    </Dialog>
  );
};

export default ConfirmationModal;
