const getFixedLocalTimeDate = (dateTimeString: string): Date => {
  const [year, month, day, hour, minute] = dateTimeString
    .split(/[-T:+]/)
    .map(Number);

  // Create a Date object directly as local time
  return new Date(year, month - 1, day, hour, minute);
};

export default getFixedLocalTimeDate;
