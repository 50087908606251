import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApptUserPreferenceState } from "./apptUserPreference.types";

// Initial state occurring when Appointment user preference is not initialized yet
// All fields should be NULL when not logged in yet.
export const initialState: ApptUserPreferenceState = {
  lastFiveInstitutionSearchedKeywords: [],
  lastFiveSpecialtySearchedKeywords: [],
  lastFivePractitionerSearchedKeywords: [],
};

/**
 * The slice   reducer for the part of state that represents the logged in user.
 * Contains the user preference information.
 */
const apptUserPreferenceSlice = createSlice({
  name: "apptUserPreference",
  initialState,
  reducers: {
    updateInsUserPreference(state, action: PayloadAction<string>) {
      const index: number = state.lastFiveInstitutionSearchedKeywords.indexOf(
        action.payload,
      );
      //if the list has contain the str, remove it before adding it to the end again
      if (index !== -1) {
        state.lastFiveInstitutionSearchedKeywords.splice(index, 1);
      }
      if (state.lastFiveInstitutionSearchedKeywords.length < 5) {
        state.lastFiveInstitutionSearchedKeywords.push(action.payload);
      } else {
        state.lastFiveInstitutionSearchedKeywords.push(action.payload);
        state.lastFiveInstitutionSearchedKeywords.shift();
      }
    },
    updateSpecialtyUserPreference(state, action: PayloadAction<string>) {
      const index: number = state.lastFiveSpecialtySearchedKeywords.indexOf(
        action.payload,
      );
      //if the list has contain the str, remove it before adding it to the end again
      if (index !== -1) {
        state.lastFiveSpecialtySearchedKeywords.splice(index, 1);
      }
      if (state.lastFiveSpecialtySearchedKeywords.length < 5) {
        state.lastFiveSpecialtySearchedKeywords.push(action.payload);
      } else {
        state.lastFiveSpecialtySearchedKeywords.push(action.payload);
        state.lastFiveSpecialtySearchedKeywords.shift();
      }
    },
    updatePractitionerUserPreference(state, action: PayloadAction<string>) {
      const index: number = state.lastFivePractitionerSearchedKeywords.indexOf(
        action.payload,
      );
      //if the list has contain the str, remove it before adding it to the end again
      if (index !== -1) {
        state.lastFivePractitionerSearchedKeywords.splice(index, 1);
      }
      if (state.lastFivePractitionerSearchedKeywords.length < 5) {
        state.lastFivePractitionerSearchedKeywords.push(action.payload);
      } else {
        state.lastFivePractitionerSearchedKeywords.push(action.payload);
        state.lastFivePractitionerSearchedKeywords.shift();
      }
    },
  },
});

export const {
  updateInsUserPreference,
  updateSpecialtyUserPreference,
  updatePractitionerUserPreference,
} = apptUserPreferenceSlice.actions;

export default apptUserPreferenceSlice.reducer;
