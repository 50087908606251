import { useAppDispatch, useAppSelector } from "lib/redux/hooks";
import HeaderBlock from "../../components/HeaderBlock/HeaderBlock";
import { selectCreateAppointment } from "ui/appointment/ducks/selectors";
import { Box, Stack, Typography, useAutocomplete } from "@mui/material";
import SimpleAutocomplete from "lib/components/formInputs/SimpleAutocomplete/SimpleAutocomplete";
import AccordionBlock from "lib/components/accordion/AccordionBlock";
import { useEffect, useMemo, useState } from "react";
import SelectionListItem from "ui/appointment/components/common/selection-listing/SelectionListItem/SelectionListItem";
import { updatePractitionerForCreate } from "ui/appointment/ducks/appointmentsSlice";
import { useGetPractitionerList } from "ui/appointment/hooks/useGetPractitionerList";
import { selectUser } from "lib/redux/user/selectors";
import { PractitionerGroups } from "api/appointment/GetPractitionerList/GetPractitionerList.types";
import { useNavigate } from "react-router-dom";
import { PATHS } from "lib/routing";
import ErrorDisplay from "lib/components/error/ErrorDisplay/ErrorDisplay";
import PractitionerSelectionPageSkeleton from "./PractitionerSelectionPageSkeleton";
import { selectApptUserPreference } from "lib/redux/apptUserPreference/selectors";
import { updatePractitionerUserPreference } from "lib/redux/apptUserPreference/apptUserPreferenceSlice";

const PractitionerSelectionPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Redux states
  const { selectedInstitution, selectedSpecialty, selectedInstitutionCode } =
    useAppSelector(selectCreateAppointment);
  const { memberIdentifier } = useAppSelector(selectUser);
  const { lastFivePractitionerSearchedKeywords: lastFiveSearchedKeywords } =
    useAppSelector(selectApptUserPreference);
  // Local states
  const [expandedAccordions, setExpandedAccordions] = useState<string[]>([]);

  const { data, isLoading, hasErrored, errorMessage } = useGetPractitionerList({
    specialtyCode: selectedSpecialty?.code ?? "",
    institutionCode: selectedInstitutionCode ?? "",
    memberIdentifier: memberIdentifier ?? "",
  });

  const onSavePractitionerKeyword = (keyword: string) => {
    dispatch(updatePractitionerUserPreference(keyword));
  };
  // Reset consultant state
  useEffect(() => {
    dispatch(
      updatePractitionerForCreate({
        name: "",
        practitionerId: "",
        category: "",
      }),
    );
  }, [dispatch]);

  const transformedConsultantsList = useMemo(() => {
    return data ? transformConsultantsList(data) : [];
  }, [data]);

  const { getInputProps, groupedOptions, inputValue, focused } =
    useAutocomplete({
      options: transformedConsultantsList,
      getOptionLabel: (option) => option.name,
    });

  return (
    <>
      {isLoading ? (
        <>
          <HeaderBlock
            location={selectedInstitution}
            service={
              selectedSpecialty?.description
                ? `Private ${selectedSpecialty?.description} - First Visit`
                : ""
            }
          />
          <Stack gap={2} sx={{ m: 2 }}>
            <Typography
              variant="header4_semibold"
              sx={(theme) => ({ color: theme.palette.primary.dark })}
            >
              Select Doctor
            </Typography>
          </Stack>
          <PractitionerSelectionPageSkeleton />
        </>
      ) : hasErrored ? (
        <ErrorDisplay errorMessage={errorMessage} />
      ) : (
        <>
          <HeaderBlock
            location={selectedInstitution}
            service={
              selectedSpecialty?.description
                ? `Private ${selectedSpecialty?.description} - First Visit`
                : ""
            }
          />
          <Stack gap={2} sx={{ m: 2 }}>
            <Typography
              variant="header4_semibold"
              sx={(theme) => ({ color: theme.palette.primary.dark })}
            >
              Select Doctor
            </Typography>
            <Box sx={{ zIndex: 100 }}>
              <SimpleAutocomplete
                placeholder="Search doctor"
                items={lastFiveSearchedKeywords}
                showHistoryKeyword={focused && inputValue.length === 0}
                onSelect={(selectedValue) => {
                  const consultant = transformedConsultantsList.find(
                    (consultant) => consultant.name === selectedValue,
                  );
                  if (consultant) {
                    dispatch(updatePractitionerForCreate(consultant));
                    onSavePractitionerKeyword(selectedValue);
                    navigate(PATHS.APPOINTMENT_CREATE_AVAILABLE_SLOTS.path);
                  }
                }}
                inputProps={getInputProps}
              />
            </Box>
          </Stack>

          {(groupedOptions.length === 0 && inputValue.length !== 0) ||
          transformedConsultantsList.length === 0 ? (
            <Box mx={2} mt={11}>
              <Typography
                variant="body2_regular"
                sx={(theme) => ({
                  color: theme.palette.grey[600],
                  textAlign: "center",
                })}
              >
                No doctors found
              </Typography>
            </Box>
          ) : groupedOptions.length === 0 && data ? (
            Object.keys(data).map(
              (key) =>
                data[key].length > 0 && (
                  <AccordionBlock
                    key={key}
                    summaryBackgroundColor="secondary.lighter"
                    summaryNode={
                      <Typography variant="header4_semibold">{key}</Typography>
                    }
                    detailsNode={data[key].map((item) => (
                      <SelectionListItem
                        key={item.PractitionerId}
                        itemName={item.Name}
                        onClick={() => {
                          const consultant = transformedConsultantsList.find(
                            (consultant) => consultant.name === item.Name,
                          );

                          if (consultant) {
                            dispatch(updatePractitionerForCreate(consultant));
                            onSavePractitionerKeyword(consultant.name);
                            navigate(
                              PATHS.APPOINTMENT_CREATE_AVAILABLE_SLOTS.path,
                            );
                          }
                        }}
                      />
                    ))}
                    expanded={expandedAccordions.includes(key)}
                    onExpand={() => {
                      if (expandedAccordions.includes(key)) {
                        setExpandedAccordions(
                          expandedAccordions.filter(
                            (expandedKey) => expandedKey !== key,
                          ),
                        );
                      } else {
                        setExpandedAccordions([...expandedAccordions, key]);
                      }
                    }}
                  />
                ),
            )
          ) : (
            data &&
            Object.keys(data).map(
              (key) =>
                groupedOptions.some(
                  (item) => "category" in item && item.category === key,
                ) && (
                  <AccordionBlock
                    key={key}
                    summaryBackgroundColor="secondary.lighter"
                    summaryNode={
                      <Typography variant="header4_semibold">{key}</Typography>
                    }
                    detailsNode={groupedOptions.map((item) =>
                      "name" in item &&
                      "category" in item &&
                      item.category === key ? (
                        <SelectionListItem
                          key={item.practitionerId}
                          itemName={item.name ?? ""}
                          onClick={() => {
                            const consultant = transformedConsultantsList.find(
                              (consultant) => consultant.name === item.name,
                            );

                            if (consultant) {
                              dispatch(updatePractitionerForCreate(consultant));
                              onSavePractitionerKeyword(consultant.name);
                              navigate(
                                PATHS.APPOINTMENT_CREATE_AVAILABLE_SLOTS.path,
                              );
                            }
                          }}
                        />
                      ) : null,
                    )}
                    expanded
                    onExpand={() => {
                      if (expandedAccordions.includes(key)) {
                        setExpandedAccordions(
                          expandedAccordions.filter(
                            (expandedKey) => expandedKey !== key,
                          ),
                        );
                      } else {
                        setExpandedAccordions([...expandedAccordions, key]);
                      }
                    }}
                  />
                ),
            )
          )}
        </>
      )}
    </>
  );
};

// Helper function
const transformConsultantsList = (consultantsList: PractitionerGroups) => {
  return Object.keys(consultantsList).flatMap((type) =>
    consultantsList[type].map((consultant) => ({
      category: type,
      name: consultant.Name,
      practitionerId: consultant.PractitionerId,
    })),
  );
};

export default PractitionerSelectionPage;
