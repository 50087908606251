import { Box, Typography } from "@mui/material";
import { sxStyles } from "./AppointmentFormHeader.styles";
import { AppointmentFormHeaderProps } from "./AppointmentFormHeader.types";
import SharpNoticePanel from "lib/components/notice/RoundedNoticePanel/SharpNoticePanel";

const AppointmentFormHeader = ({
  alert,
  mainInfo,
}: AppointmentFormHeaderProps) => {
  const classes = sxStyles();

  return (
    <>
      {alert && (
        <Box sx={classes.infoBox}>
          <SharpNoticePanel bgColor="extremeWarn" showError>
            {alert}
          </SharpNoticePanel>
        </Box>
      )}

      {mainInfo && (
        <Typography variant="body2" sx={classes.contentText} paragraph>
          {mainInfo}
        </Typography>
      )}
    </>
  );
};

export default AppointmentFormHeader;
